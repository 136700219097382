import * as React from 'react'
import { useForm } from '@hooks'
import { postData } from '@utils'

export const ClickToCall = ({ model, dealerId }) => {
  const [dealerPhone, setDealerPhone] = React.useState()
  const [error, setError] = React.useState(false)
  const [requestCallError, setRequestCallError] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const { inputs, handleChange } = useForm({
    phoneNumber: '',
  })

  React.useEffect(() => {
    let waitForDealerIdToBeSet = setTimeout(() => {
      const dealerId = window.sessionStorage.getItem('dealerId');
      fetch(`/api/dealer?dealerId=${dealerId}`)
        .then((response) => response.json())
        .then((data) => {
          setDealerPhone(data.dealer.phone)
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
    }, 500)

    return () => {
      clearTimeout(waitForDealerIdToBeSet)
    }
  }, [])

  async function submit(e) {
    e.preventDefault()
    await postData('/api/call', {
      phoneNumber: inputs.phoneNumber,
      salesNumber: dealerPhone,
      modelName: model || 'coach',
      dealerId: window.sessionStorage.getItem('dealerId') || '11111'
    })
      .then((res) => {
        setSuccess(res.message)
      })
      .catch((err) => {
        setRequestCallError('Oops! Something went wrong.')
      })
  }

  if (error) return null

  return (
    <section className="" data-module="ClickToCall">
      <div className="bg-white">
        <div className="">
          {success ? (
            <p>{success}</p>
          ) : (
            <>
              <h2 className="text-xl font-bold tracking-wider uppercase text-brand-gray-500 sm:text-2xl">
                <span className="block">Need Assistance?</span>
                <span className="block text-brand-red-200">
                  Let us connect you with someone who can help.
                </span>
              </h2>
              <form className="mt-8 sm:flex" method="POST" onSubmit={submit}>
                <label htmlFor="phoneNumber" className="sr-only">
                  Phone Number
                </label>
                <input
                  disabled={error}
                  value={inputs.phoneNumber}
                  onChange={handleChange}
                  name="phoneNumber"
                  type="tel"
                  autoComplete="tel"
                  required
                  className={`${error && `opacity-25 cursor-not-allowed`
                    } w-full px-5 py-3 placeholder-gray-500 focus:ring-2 focus:ring-brand-red-200 sm:max-w-xs border-black border-3 focus:ring-offset-2 focus:border-brand-gray-400`}
                  placeholder="Enter your phone number"
                />
                <div className="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button
                    disabled={error}
                    type="submit"
                    className={`${error && `opacity-25 cursor-not-allowed`
                      } w-full flex items-center justify-center px-7 py-3 border-3 border-brand-red-200 text-base font-bold uppercase tracking-wide text-brand-gray-500 hover:text-white hover:bg-brand-red-200 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200`}
                  >
                    Request a call
                  </button>
                </div>
                <div className="mt-4 text-gray-500">
                  {requestCallError && (
                    <p className="text-brand-red-200">{requestCallError}</p>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
