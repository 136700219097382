import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import clientConfig from '@client-config'
import Img from 'gatsby-image'

export const SimpleHero = ({
  headingLineOne,
  headingLineTwo,
  body,
  cta,
  backgroundImage,
  backgroundVideo,
  overlay,
  video,
}) => {
  const fluidProps = getFluidGatsbyImage(
    backgroundImage?.asset?._id,
    { maxWidth: 2600 },
    clientConfig.sanity
  )
  const { url } = video ? video : 'null'
  return (
    <section className="" data-module="SimpleHero">
      <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span className="mt-1 block text-5xl tracking-tight font-display font-light sm:text-5xl xl:text-6xl leading-tight">
            <span className="block text-black">{headingLineOne}</span>
            <span className="block text-brand-red-200 relative">
              {headingLineTwo}
            </span>
          </span>
        </h1>
        <p className="mt-3 text-base font-extralight text-brand-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          {body}
        </p>
      </div>
      {backgroundImage && (
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative mx-auto w-full shadow-lg lg:max-w-md">
            {!url && <Img fluid={fluidProps} className="w-full" />}
            {url && (
              <button
                type="button"
                className="relative block w-full bg-white overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200"
              >
                <span className="sr-only">Watch our video to learn more</span>
                <Img fluid={fluidProps} className="w-full" />
                <div
                  className="absolute inset-0 w-full h-full flex items-center justify-center"
                  aria-hidden="true"
                >
                  <svg
                    className="h-20 w-20 text-brand-red-200"
                    fill="currentColor"
                    viewBox="0 0 84 84"
                  >
                    <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                    <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                  </svg>
                </div>
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  )
}
