import React from 'react'
import { Fade, Link } from '@elements'

export const Callout = ({ copy, cta, heading, colorMode }) => {
  const textColor =
    colorMode === 'light' ? 'text-gray bg-white' : 'text-white bg-gray'
  return (
    <section
      className={`relative z-0 py-20 ${textColor} bg-center bg-cover lg:bg-right-top text-center`}
    >
      <div className="container relative z-20 flex justify-center max-w-screen-xl">
        <div className="md:w-3/5">
          <Fade isActive={true}>
            <h2 className="font-serif text-5xl font-medium leading-none md:text-6xl">
              {heading}
            </h2>
            <p className="py-8 text-lg ">{copy}</p>
            {cta && cta.pageRoute?.slug?.current && (
              <div className="">
                <Link to={`/${cta.pageRoute.slug.current}`} kind={cta.kind}>
                  {cta.text}
                </Link>
              </div>
            )}
          </Fade>
        </div>
      </div>
    </section>
  )
}
