import React from 'react'
import { Link } from '@elements'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Img from 'gatsby-image'
import clientConfig from '@client-config'
import { useLocation } from '@reach/router'

export const ModelFeatures = ({ features, slug }) => {
  const location = useLocation()
  const pageSlug = location.pathname.split('/').filter(Boolean).slice(-1)[0]
  return (
    <section className="relative bg-white" data-module="ModelFeatures">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center pb-6">
          <h2 className="text-lg tracking-wider uppercase font-bold text-brand-gray-500">
            What you’ll find inside
          </h2>
        </div>
        <div className="flex flex-col gap-y-12">
          {features.map(
            (feature, id) =>
              pageSlug !==
                (feature.headingLineOne + ' ' + feature.headingLineTwo)
                  .replace(/[^a-zA-Z\d\s:]/gi, '')
                  .replace(/\s+/g, '-')
                  .toLowerCase() && (
                <div
                  className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
                  key={id}
                >
                  <div className="flex flex-col shadow overflow-hidden">
                    <div className="flex-shrink-0">
                      {feature.image?.asset?._id && (
                        <Img
                          fluid={getFluidGatsbyImage(
                            feature.image?.asset?._id,
                            { maxWidth: 800 },
                            clientConfig.sanity
                          )}
                          alt={feature.image?.alt}
                          className="w-full h-full"
                        />
                      )}
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <div className="block mt-2">
                          <p className="text-lg uppercase font-bold text-brand-gray-500 tracking-wider">
                            {feature.headingLineOne +
                              ' ' +
                              feature.headingLineTwo}
                          </p>
                          <p className="mt-3 text-base font-extralight text-brand-gray-500">
                            {feature.body}
                          </p>
                          {feature.details.length !== 0 && (
                            <Link
                              to={
                                '/models/' +
                                slug.current +
                                '/' +
                                (
                                  feature.headingLineOne +
                                  ' ' +
                                  feature.headingLineTwo
                                )
                                  .replace(/[^a-zA-Z\d\s:]/gi, '')
                                  .replace(/\s+/g, '-')
                                  .toLowerCase()
                              }
                              className="block mt-2"
                            >
                              <p className="text-brand-red-200 font-semibold mt-4">
                                Read More &rarr;
                              </p>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </section>
  )
}
