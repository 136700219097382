import React from 'react'
import { Link } from '@elements'
export const SimpleCta = ({ link }) => {
  return (
    <section className="bg-white" data-module="SimpleCTA">
      <div className="sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={link}
          className="inline-block w-full flex items-center justify-center px-4 md:px-7 py-3 border-3 border-brand-red-200 text-sm md:text-base font-bold uppercase tracking-wide text-brand-gray-500 hover:text-white hover:bg-brand-red-200 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200 transition duration-150"
        >
          See all floor plans
        </Link>
      </div>
    </section>
  )
}
