import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Img from 'gatsby-image'
import clientConfig from '@client-config'
export const Features = ({ features }) => {
  return (
    <div className="relative bg-white" data-module="Features">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-lg tracking-wider uppercase font-bold text-brand-gray-500">
            What you’ll find inside
          </h2>
        </div>
        {features.map((feature, id) => (
          <div
            className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
            key={id}
          >
            <div className="flex flex-col shadow overflow-hidden">
              <div className="flex-shrink-0">
                {feature.image?.asset?._id && (
                  <Img
                    fluid={getFluidGatsbyImage(
                      feature.image?.asset?._id,
                      { maxWidth: 800 },
                      clientConfig.sanity
                    )}
                    alt={feature.image?.alt}
                    className="w-full h-full"
                  />
                )}
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-lg uppercase font-bold text-brand-gray-500 tracking-wider">
                      {feature.heading}
                    </p>
                    <p className="mt-3 text-base font-extralight text-brand-gray-500">
                      {feature.body}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
