import React, { useState, useEffect } from 'react'
import { Link } from '@elements'
import { graphql, useStaticQuery } from 'gatsby'

export const ModelList = ({ headingLineOne, headingLineTwo }) => {
  // Build Time Data Fetching
  const modelData = useStaticQuery(graphql`
    query {
      allSanityModel {
        nodes {
          slug {
            current
          }
          name
          class
          year
        }
      }
      allSanityModelYearSettings {
        nodes {
          currentModelYear
        }
      }
    }
  `)

  const [modelsCarried, setModelsCarried] = useState([])
  const [dealerId, setDealerId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(false)

  useEffect(() => {
    setLoading(true)
    const dealer = window.sessionStorage.getItem('dealerId')
    const currentYear = () => {
      const date =
        modelData.allSanityModelYearSettings.nodes[0].currentModelYear
      var d = new Date(date)
      var y = d.getUTCFullYear()
      return String(y)
    }

    const currentYearModels = modelData.allSanityModel.nodes.filter((coach) => {
      return coach.year === currentYear()
    })

    const setType = async (models) => {
      const classA = []
      const classC = []
      await models.map((unit) => {
        if (unit.class === 'A') {
          classA.push(unit)
        } else {
          classC.push(unit)
        }
        return unit
      })
      const sorted = {
        classA: classA,
        classC: classC,
      }
      return sorted
    }

    setType(currentYearModels).then((response) => {
      setError(false)
      setModelsCarried(response)
      setLoading(false)
    })
  }, [
    modelData.allSanityModel.nodes,
    modelData.allSanityModelYearSettings.nodes,
  ])
  return (
    <section>
      <div className="">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h1>
            <span className="mt-1 block text-5xl tracking-tight font-display font-light sm:text-5xl xl:text-6xl leading-tight">
              <span className="text-black">{headingLineOne}</span>
              <span className="text-brand-red-200 relative">
                {' '}
                {headingLineTwo}
              </span>
            </span>
          </h1>
        </div>
      </div>
      <div>
        {loading && (
          <div className="w-full h-96 animate-pulse">
            Looking for models at your dealer...
          </div>
        )}
        {error && (
          <div className="mb-4">
            <i>{message}</i>
            <div className="" id="classA">
              {!loading && (
                <div>
                  <ModelLinks title="Class A" models={modelsCarried.classA} />
                  <ModelLinks title="Class C" models={modelsCarried.classC} />
                </div>
              )}
            </div>
          </div>
        )}
        <div id="classA">
          {!loading && !error && (
            <div className="mb-4">
              <i>{message}</i>
              <div>
                <ModelLinks
                  title="Class A"
                  models={modelsCarried.classA}
                  dealer={dealerId}
                />
                <ModelLinks
                  title="Class C"
                  models={modelsCarried.classC}
                  dealer={dealerId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
function ModelLinks({ title, models, dealer = null }) {
  return (
    <>
      {models && models.length > 0 && (
        <div className="py-6">
          <h2 className="text-lg tracking-wider uppercase font-bold text-brand-gray-500">
            {title}
          </h2>
          <div className="mt-4 flex flex-col space-y-8">
            {models.map((model, idx) => {
              return (
                <Link
                  key={idx}
                  className="w-full flex items-center justify-center px-7 py-3 border-3 border-brand-red-200 text-base font-bold uppercase tracking-wide text-brand-gray-500 hover:text-white hover:bg-brand-red-200 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200"
                  to={`/models/${model.slug.current}${dealer ? `?dealerId=${dealer}` : ``
                    }`}
                >
                  {model.name}
                </Link>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
