import React from 'react'

export const Testimonials = ({ quotes }) => {
  return (
    <>
      {quotes.length > 0 && (
        <section className="relative shadow" data-module="Testimonials">
          <div
            className="bg-brand-gray-500 w-full left-0 h-32 absolute"
            style={{
              marginLeft: '-1rem',
              marginRight: '-1rem',
              width: 'calc(100% + 2rem)',
              height: '100%',
              zIndex: '0',
            }}
          ></div>
          <div className="z-10 relative">
            <div className="text-center pt-12 md:pt-16 md:hidden">
              <h2 className="text-lg uppercase font-bold text-white tracking-wider">
                What others are saying
              </h2>
            </div>
            <div className="max-w-7xl mx-auto">
              {quotes.map((quote, idx) => (
                <div
                  key={idx}
                  className={
                    'pt-4 pb-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-brand-gray-300 lg:pr-16' +
                    (idx % 2 === 0
                      ? ''
                      : ' border-t-2 border-brand-gray-300 md:border-t-0 md:border-l ')
                  }
                >
                  <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-brand-gray-300"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                      </svg>
                      <p className="relative text-white font-light">
                        {quote.body}
                      </p>
                    </div>
                    <footer className="mt-8">
                      <div className="flex items-start">
                        <div className="ml-4">
                          <div className="text-sm text-white uppercase font-bold tracking-wide">
                            {quote.personName}
                          </div>
                          <div className="text-base font-extralight text-brand-gray-200">
                            {quote.location}
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  )
}
