import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { useInView } from 'react-intersection-observer'
import Img from 'gatsby-image'
import { motion, useAnimation } from 'framer-motion'
import clientConfig from '@client-config'

export const Combo = ({ heading, copy, image, alignment }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.25 })
  const controls = useAnimation()

  React.useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }
  }, [controls, inView])

  const variants = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.15,
        duration: 0.35,
        ease: [0.81, 0.3, 0.23, 0.65],
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
        duration: 0.25,
        ease: [0.81, 0.3, 0.23, 0.65],
      },
    },
  }
  const frontImage = getFluidGatsbyImage(
    image?.asset?._id,
    { maxWidth: 800 },
    clientConfig.sanity
  )
  const imagePlacement =
    alignment === 'right' && image?.asset?._id ? 'flex-row-reverse' : ''
  return (
    <div className="mt-12 max-w-lg mx-auto space-y-12">
      <motion.div
        ref={ref}
        animate={controls}
        variants={variants}
        initial="hidden"
        className={`flex flex-wrap ${imagePlacement} items-center`}
      >
        {image?.asset?._id && (
          <div className=" mb-0 w-1/2">
            <Img fluid={frontImage} className=" h-32 w-full" alt={image?.alt} />
          </div>
        )}
        <div className={image?.asset?._id && 'w-1/2'}>
          <div className="px-4">
            <p className="mt-1 text-base font-extralight text-brand-gray-500">
              {copy}
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
