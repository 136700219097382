import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

export const Content = ({ content, className }) => {
  return (
    <section className={className}>
      <div className="container prose">
        <BlockContent blocks={content} />
      </div>
    </section>
  )
}
