import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'
import clientConfig from '@client-config'
import { motion, AnimatePresence } from 'framer-motion'

export const ModelHero = ({ name, price, body, video }) => {
  const thumbnailImage = video?.thumbnail?.asset?._id
    ? getFluidGatsbyImage(
        video?.thumbnail.asset?._id,
        { maxWidth: 800 },
        clientConfig.sanity
      )
    : false
  const [isPlaying, setIsPlaying] = React.useState(false)
  return (
    <section className="bg-white relative" data-module="ModelHero">
      <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <span className="mt-1 block text-5xl tracking-tight font-display font-light sm:text-5xl xl:text-6xl leading-tight">
            <span className="block text-black">Welcome to</span>
            <span className="block text-brand-red-200 relative">{name}</span>
          </span>
        </h1>
        <h2 className="text-sm tracking-wider uppercase font-bold text-brand-gray-500 mt-2">
          STARTING AT&nbsp;$
          {price.toLocaleString(undefined, { maximumFractionDigits: 0 })} USD
        </h2>
        <p className="mt-3 text-base font-extralight text-brand-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          {body}
        </p>
        {video?.url && thumbnailImage && (
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative h-72 mx-auto w-full shadow-lg lg:max-w-md">
              <div
                key="video"
                className={
                  'absolute inset-0 w-full h-full' /* +
                      (isPlaying ? ' block ' : ' hidden ') */
                }
              >
                <ReactPlayer
                  url={video.url}
                  height="100%"
                  width="100%"
                  playing
                  controls
                  playIcon={<PlayButton />}
                  playsinline
                  light
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
  function toggleVideo() {
    setIsPlaying(!isPlaying)
  }
  function PlayButton() {
    return (
      <button
        key="still"
        type="button"
        onClick={toggleVideo}
        className={
          'left-0 right-0 block w-full bg-white overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-red-200' /*  + (isPlaying ? ' hidden ' : ' block ') */
        }
      >
        <span className="sr-only">Watch our video to learn more</span>
        <Img className="w-full" fluid={thumbnailImage} alt="" />
        <div
          className="absolute inset-0 w-full h-full flex items-center justify-center"
          aria-hidden="true"
        >
          <svg
            className="h-20 w-20 text-brand-red-200"
            fill="currentColor"
            viewBox="0 0 84 84"
          >
            <circle opacity="0.9" cx="42" cy="42" r="42" fill="white"></circle>
            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"></path>
          </svg>
        </div>
      </button>
    )
  }
}
